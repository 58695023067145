import $ from 'jquery'

// URL Tracking

$('#nav-to-top a').on('click', function () {
	dataLayer.push({
		event: 'virtualpageview',
		virtualPageURL: '/',
		virtualPageTitle: 'Up to Top'
	})
})

$('#nav-one a').on('click', function () {
	dataLayer.push({
		event: 'virtualpageview',
		virtualPageURL: '/digital-exbeerience/',
		virtualPageTitle: 'A great digital exbeerience'
	})
})

$('#nav-two a').on('click', function () {
	dataLayer.push({
		event: 'virtualpageview',
		virtualPageURL: '/what-we-know/',
		virtualPageTitle: 'What we know'
	})
})

$('#nav-three a').on('click', function () {
	dataLayer.push({
		event: 'virtualpageview',
		virtualPageURL: '/ux/',
		virtualPageTitle: 'Science of UX'
	})
})

// $('#nav-four a').on('click', function() {
//     dataLayer.push({
//         event: 'virtualpageview',
//         virtualPageURL: '/moving/',
//         virtualPageTitle: "Moving people to action"
//     });
// });

$('#nav-five a').on('click', function () {
	dataLayer.push({
		event: 'virtualpageview',
		virtualPageURL: '/technology/',
		virtualPageTitle: 'Technically Speaking'
	})
})

$('#nav-six a').on('click', function () {
	dataLayer.push({
		event: 'virtualpageview',
		virtualPageURL: '/our-process/',
		virtualPageTitle: 'Our process'
	})
})

$('#nav-seven a').on('click', function () {
	dataLayer.push({
		event: 'virtualpageview',
		virtualPageURL: '/paradowski/',
		virtualPageTitle: 'Want to know more?'
	})
})

// Event Tracking

// $('#what-we-know .quote-play').on('click', function() {
//     dataLayer.push({
//         event: 'genericLinkClick',
//         eventCategory: 'media_audio',
//         eventAction: 'audio_play',
//         eventLabel: 'jcraw_quote',
//         eventNonInteraction: false
//     });
// });

$('#modern-ag-innovation').on('click', function () {
	dataLayer.push({
		event: 'genericLinkClick',
		eventCategory: 'interaction',
		eventAction: 'readmore_open',
		eventLabel: 'modern_ag',
		eventNonInteraction: true
	})
})

$('#opera-theatre').on('click', function () {
	dataLayer.push({
		event: 'genericLinkClick',
		eventCategory: 'interaction',
		eventAction: 'rm_expand',
		eventLabel: 'opera_theatre',
		eventNonInteraction: true
	})
})

$('#t-rex').on('click', function () {
	dataLayer.push({
		event: 'genericLinkClick',
		eventCategory: 'interaction',
		eventAction: 'readmore_open',
		eventLabel: 'modern_ag',
		eventNonInteraction: true
	})
})

$('#boat-planet').on('click', function () {
	dataLayer.push({
		event: 'genericLinkClick',
		eventCategory: 'interaction',
		eventAction: 'rm_expand',
		eventLabel: 'opera_theatre',
		eventNonInteraction: true
	})
})

$('.screen-comp-modals .comps-1').on('click', function () {
	dataLayer.push({
		event: 'genericLinkClick',
		eventCategory: 'interaction',
		eventAction: 'image_popup',
		eventLabel: 'prev_comp_1',
		eventNonInteraction: true
	})
})
$('.screen-comp-modals .comps-2').on('click', function () {
	dataLayer.push({
		event: 'genericLinkClick',
		eventCategory: 'interaction',
		eventAction: 'image_popup',
		eventLabel: 'prev_comp_2',
		eventNonInteraction: true
	})
})
$('.screen-comp-modals .comps-3').on('click', function () {
	dataLayer.push({
		event: 'genericLinkClick',
		eventCategory: 'interaction',
		eventAction: 'image_popup',
		eventLabel: 'prev_comp_3',
		eventNonInteraction: true
	})
})
$('.screen-comp-modals .comps-4').on('click', function () {
	dataLayer.push({
		event: 'genericLinkClick',
		eventCategory: 'interaction',
		eventAction: 'image_popup',
		eventLabel: 'prev_comp_4',
		eventNonInteraction: true
	})
})
$('.screen-comp-modals .comps-5').on('click', function () {
	dataLayer.push({
		event: 'genericLinkClick',
		eventCategory: 'interaction',
		eventAction: 'image_popup',
		eventLabel: 'prev_comp_5',
		eventNonInteraction: true
	})
})

$('.accordion .photography').on('click', function () {
	dataLayer.push({
		event: 'genericLinkClick',
		eventCategory: 'interaction',
		eventAction: 'expander_open',
		eventLabel: 'photo_expand',
		eventNonInteraction: true
	})
})

$('.accordion .not-your-user').on('click', function () {
	dataLayer.push({
		event: 'genericLinkClick',
		eventCategory: 'interaction',
		eventAction: 'expander_open',
		eventLabel: 'notuser_expand',
		eventNonInteraction: true
	})
})

$('.accordion .responsive').on('click', function () {
	dataLayer.push({
		event: 'genericLinkClick',
		eventCategory: 'interaction',
		eventAction: 'expander_open',
		eventLabel: 'response_expand',
		eventNonInteraction: true
	})
})

$('.accordion .seo').on('click', function () {
	dataLayer.push({
		event: 'genericLinkClick',
		eventCategory: 'interaction',
		eventAction: 'expander_open',
		eventLabel: 'seo_expand',
		eventNonInteraction: true
	})
})

$('.accordion .accessibility').on('click', function () {
	dataLayer.push({
		event: 'genericLinkClick',
		eventCategory: 'interaction',
		eventAction: 'expander_open',
		eventLabel: 'access_expand',
		eventNonInteraction: true
	})
})

$('.accordion .measurement').on('click', function () {
	dataLayer.push({
		event: 'genericLinkClick',
		eventCategory: 'interaction',
		eventAction: 'expander_open',
		eventLabel: 'measure_expand',
		eventNonInteraction: true
	})
})

$('.accordion .sitefinity').on('click', function () {
	dataLayer.push({
		event: 'genericLinkClick',
		eventCategory: 'interaction',
		eventAction: 'expander_open',
		eventLabel: 'sitefinity_expand',
		eventNonInteraction: true
	})
})

$('.accordion .design-language').on('click', function () {
	dataLayer.push({
		event: 'genericLinkClick',
		eventCategory: 'interaction',
		eventAction: 'expander_open',
		eventLabel: 'designlang_expand',
		eventNonInteraction: true
	})
})

$('.accordion .content-creation').on('click', function () {
	dataLayer.push({
		event: 'genericLinkClick',
		eventCategory: 'interaction',
		eventAction: 'expander_open',
		eventLabel: 'content_expand',
		eventNonInteraction: true
	})
})

$('.card.phase-1').on('click', function () {
	dataLayer.push({
		event: 'genericLinkClick',
		eventCategory: 'interaction',
		eventAction: 'expander_open',
		eventLabel: 'phase1_expand',
		eventNonInteraction: true
	})
})

$('.card.phase-2').on('click', function () {
	dataLayer.push({
		event: 'genericLinkClick',
		eventCategory: 'interaction',
		eventAction: 'expander_open',
		eventLabel: 'phase2_expand',
		eventNonInteraction: true
	})
})

$('.card.phase-3').on('click', function () {
	dataLayer.push({
		event: 'genericLinkClick',
		eventCategory: 'interaction',
		eventAction: 'expander_open',
		eventLabel: 'phase3_expand',
		eventNonInteraction: true
	})
})

$('.card.phase-4').on('click', function () {
	dataLayer.push({
		event: 'genericLinkClick',
		eventCategory: 'interaction',
		eventAction: 'expander_open',
		eventLabel: 'phase4_expand',
		eventNonInteraction: true
	})
})

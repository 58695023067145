import $ from 'jquery'
//
// import "scrollmagic";
//
window.ScrollMagic = require('scrollmagic')

var controller = new ScrollMagic.Controller()

$(function () {
	// wait for document ready
	// init
	var controller = new ScrollMagic.Controller({
		globalSceneOptions: {
			triggerHook: 'onLeave'
		}
	})

	// Values for the start and end of making sure a class
	// is added to trick Chrome into rendering the Paradowski
	// logo mask correctly
	const SVG_VISIBLE_START = 0.45
	// const SVG_SMOOTH_END = 0.8
	var parallaxContainer = document.getElementById('parallaxContainer')
	function headerParallaxCallback (event) {
		if (parallaxContainer && event.progress >= SVG_VISIBLE_START) {
			parallaxContainer.querySelector('.layer img').style.opacity = 1
			event.target.off('progress')
		}
	}

	if (window.matchMedia('(min-width: 1025px)').matches) {
		var containerScene = new ScrollMagic.Scene({
			triggerElement: '#body-load',
			duration: '200%'
		})
			.on('progress', headerParallaxCallback)
			.setPin('.main-header')
		// .addIndicators({name: "pin container"}) // add indicators (requires plugin)
			.addTo(controller)
	} else {
		// var containerScene = new ScrollMagic.Scene({
		//   triggerElement: "#body-load",
		//   duration: "200%"
		// })
		//   .on('progress', headerParallaxCallback)
		//   .setPin(".main-header")
		//   // .addIndicators({name: "pin container"}) // add indicators (requires plugin)
		//   .addTo(controller);
	}

	var itemsTween = new TimelineMax().add([
		TweenMax.fromTo(
			'#parallaxContainer .layer.one',
			1,
			{ transform: 'scale(500)' },
			{ transform: 'scale(1)', ease: Power3.easeOut }
		)
	])

	if (window.matchMedia('(min-width: 1025px)').matches) {
		var itemsScene = new ScrollMagic.Scene({
			triggerElement: '#body-load',
			duration: '200%'
		})
			.setTween(itemsTween)
		// .addIndicators({name: "items"}) // add indicators (requires plugin)
			.addTo(controller)
	} else {
		// var itemsScene = new ScrollMagic.Scene({
		//   triggerElement: "#body-load",
		//   duration: "200%"
		// })
		//   .setTween(itemsTween)
		//   // .addIndicators({name: "items"}) // add indicators (requires plugin)
		//   .addTo(controller);
	}
})

// fallback: show controls if autoplay fails
// (needed for Samsung Internet for Android, as of v6.4)
window.addEventListener('load', function () {
	let video = document.querySelector('video[muted][autoplay]')

	if (!video) return

	var playPromise = video.play()

	if (playPromise !== undefined) {
		playPromise.then(function () {
			// Autoplay is supported
		}).catch(function (err) {
			console.warn(err)
			video.controls = true
		})
	}
})

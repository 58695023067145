import $ from 'jquery'

$('.quote-play').click(function () {
	var audio = $(this).parents('.quote-module').find('audio:first').get(0)
	var element = $(this)

	if (audio.paused) {
		audio.play()
		$(this).find('i').removeClass('fa-play')
		$(this).find('i').addClass('fa-pause')
	} else {
		audio.pause()
		audio.currentTime = 0
		$(this).find('i').removeClass('fa-pause')
		$(this).find('i').addClass('fa-play')
	}

	audio.addEventListener('ended', function () {
		audio.pause()
		audio.currentTime = 0
		element.find('i').removeClass('fa-pause')
		element.find('i').addClass('fa-play')
	})
})
